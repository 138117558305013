/**
 * api：接口
 * data：删除的数据，单选为id值，多选传的数组
 * status：0：单选，1：多选. 2：tree的删除, 3: 其他,4 单个删除
 */
 import { ElMessageBox } from 'element-plus'
 export const deleteEvent = {
  methods: {
    deleteClick(api, data, status, key, tooltip) {
      if(status == 1 && data.length === 0) return this.$message.error('请选择内容！')
      ElMessageBox.confirm( tooltip, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let ids = [];
        if(status == 1) {
          data.forEach(item => { ids.push(item.id) })
        }else if(status == 3) {
          ids = data;
        }else {
          ids = [data]
        }
        let keys = {};
        keys[key] = ids
        this.$API[api](key ? keys : ids).then(res => {
          if(res.data.code == 200) {
            // 删除事件操作
            if(status == 2) this.deleteOne(data);
            else if(status == 3) this.deleteS();
            else if(status == 4) this.goBack();
            else { 
              this.showPage(1);
            }
            this.$message.success('操作成功')
          }else {
            this.$message.error(res.data.msg || '操作失败')
          }

        })

      }).catch(() => { return null; })
    }
  }
}

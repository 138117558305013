<template>
  <el-dialog
    v-model="visible"
    :title="detail ? '数据修改' : '数据增加'"
    width="420px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @close="closeClick"
  >
    <el-form :model="form" ref="formRef" label-width="68px" :rules="rules">
      <el-form-item label="标准号" prop="standardNumber">
        <el-input v-model="form.standardNumber" placeholder="请输入标准号" />
      </el-form-item>
      <el-form-item label="标准名称" prop="standardName">
        <el-input v-model="form.standardName" placeholder="请输入标准名称" />
      </el-form-item>
      <el-form-item label="架位" prop="position">
        <el-input v-model="form.position" placeholder="请输入架位" />
      </el-form-item>
      <el-form-item label="库存量" prop="inventory">
        <el-input-number v-model="form.inventory" :precision="0" :min="0" />
      </el-form-item>
      <el-form-item label="借出量" prop="lend">
        <el-input-number v-model="form.lend" :precision="0" :min="0" />
      </el-form-item>
      <el-form-item label="总数量">
        {{ parseInt(form.inventory) + parseInt(form.lend) }}
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="$emit('close', false)">取消</el-button>
        <el-button type="primary" @click="confirmClick">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { watch, nextTick, getCurrentInstance, ref  } from 'vue'
import { ElMessage } from 'element-plus'
export default {
  props: ['visible', 'detail'],
  setup(props, context) {
    const { appContext: { config : { globalProperties } } } = getCurrentInstance();
    let formRef = ref(null);
    let form = ref({
      standardNumber: '',
      standardName: '',
      position: '',
      inventory: 0,
      lend: 0,
    });
    let rules = ref({
      standardNumber: [
        { required: true, message: '请输入标准号', trigger: 'blur' },
      ],
      standardName: [
        { required: true, message: '请输入标准名称', trigger: 'blur' },
      ],
      position: [
        { required: true, message: '请输入架位', trigger: 'blur' },
      ],
      lend: [
        { required: true, message: '请输入借书量', trigger: 'blur' },
      ],
      inventory: [
        { required: true, message: '请输入库存量', trigger: 'blur' },
      ],
    })
    let one = ref(false)

    watch(() => props.detail, 
    (n, o) => {
      if(n) {
        form.value = { ...n };
        nextTick(() => { formRef.value && formRef.value.resetFields() })
      }
    })

    function confirmClick() {
      if(one.value) return;
      else one.value = true;
      formRef.value.validate((valid) => {
        if(valid) {
          let api = props.detail ? 'EditStandard' : 'AddStandard'
          globalProperties.$API[api]({
            id: props.detail && props.detail.id || null,
            position: form.value.position,
            standardName: form.value.standardName,
            standardNumber: form.value.standardNumber,
            lend: parseInt(form.value.lend),
            inventory: parseInt(form.value.inventory),
          }).then(res => {
            if(res.data.code == 200) {
              ElMessage.success('操作成功');
              one.value = false;
              // Object.assign($data, $options.data());
              context.emit('close', true)
            }else {
              one.value = false;
              ElMessage.error( res.data.msg || '操作失败')

            }
          })
        }else {
          one.value = false;
        }
      })
    }

    function closeClick() {
      form.value = {
        standardNumber: '',
        standardName: '',
        position: '',
        inventory: 0,
        lend: 0,
      }
      formRef.value.resetFields()
      context.emit('close', false)
    }

    return {
      form,
      rules,
      one,
      formRef,
      confirmClick,
      closeClick
    }
  },
  // watch: {
  //   detail: {
  //     handler(n) {
  //       if(n) {
  //         this.form = { ...n };
  //         this.$nextTick(() => { this.$refs.form && this.$refs.form.resetFields() })
  //       }
  //     }
  //   }
  // },
  // methods: {
  //   confirmClick() {
  //     if(this.one) return;
  //     else this.one = true;
  //     this.$refs.form.validate((valid) => {
  //       if(valid) {
  //         let api = this.detail ? 'EditStandard' : 'AddStandard'
  //         this.$API[api]({
  //           id: this.detail && this.detail.id || null,
  //           position: this.form.position,
  //           standardName: this.form.standardName,
  //           standardNumber: this.form.standardNumber,
  //           lend: parseInt(this.form.lend),
  //           inventory: parseInt(this.form.inventory),
  //         }).then(res => {
  //           if(res.data.code == 200) {
  //             this.$message.success('操作成功');
  //             this.one = false;
  //             Object.assign(this.$data, this.$options.data());
  //             this.$emit('close', true)
  //           }else {
  //             this.one = false;
  //             this.$message.error( res.data.msg || '操作失败')

  //           }
  //         })
  //       }else {
  //         this.one = false;
  //       }
  //     })
  //   },
  //   closeClick() {
  //     this.form = {
  //       standardNumber: '',
  //       standardName: '',
  //       position: '',
  //       inventory: 0,
  //       lend: 0,
  //     }
  //     this.$refs.form.resetFields()
  //     this.$emit('close', false)
  //   }
  // }
}
</script>

<style lang='scss'>
  .el-dialog {
    margin-top: 0;
    top: 50%;
    transform: translate(0, -50%);

    .el-dialog__header {
      padding: 15px;
      margin-right: 0;

      .el-dialog__headerbtn {
        width: 16px;
        height: 16px;
        margin-top: 13px;
        margin-right: 15px;

      }
    }

    .el-dialog__body {
      padding: 0 15px;

      .el-form-item {
        margin-bottom: 20px;
      }

      .el-select {
        width: 332px;
      }

      .el-form-item__label,
      .el-form-item__content,
      .el-select,
      .el-input {
        height: 36px;
        line-height: 36px;
      }
    }

    .el-dialog__footer {
      padding: 0 15px 15px;
    }

     .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
       margin-right: 0;
       content: '';
     }
  }
</style>
<template>
  <div id="searchList">
    <ListHeader @search="searchClick"></ListHeader>
    <div class="search_inner">
      <div class="inner_top">
        <p>共找到 {{ totalNum }} 条记录</p>
        <el-button @click="visible2 = true">借阅记录导出</el-button>
        <el-button type="primary" @click="visible = true">增加</el-button>
        <el-button type="danger" @click="deleteClick('DeleteStandard', checkedList, 1, 'standardIds', '此操作将删除选中的标准, 是否继续?')">删除</el-button>
      </div>
      <el-table
        ref="multipleTableRef"
        :data="tableData"
        border
        empty-text="暂无数据"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="标准号" prop="standardNumber" width="265" />
        <el-table-column label="标准名称" prop="standardName" />
        <el-table-column label="架位" width="140" prop="position" align="center" />
        <el-table-column label="总数量" width="80" prop="total" align="center" />
        <el-table-column label="库存量" width="80" prop="inventory" align="center" />
        <el-table-column label="借出量" width="80" prop="lend" align="center" />
        <el-table-column label="操作" width="155">
          <template #default="{ row }">
            <el-button text type="primary" @click="visible = true; detail = row">修改</el-button>
            <el-button text type="primary" @click="visible1 = true; type = 1; detail = row">借出</el-button>
            <el-button text type="primary" @click="visible1 = true; type = 2; detail = row">归还</el-button>
            <el-button text type="primary" @click="deleteClick('DeleteStandard', row.id, 0, 'standardIds', '此操作将删除选中的标准, 是否继续?')">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        small
        background
        layout="prev, pager, next"
        :total="totalNum"
        :page-size="pageSize"
        v-model="pageNum"
        @current-change="pageChange"
      />
    </div>
    <RecordExportModal :visible="visible2" @close="closeExportClick"></RecordExportModal>
    <UpdateData :visible="visible" :detail="detail" @close="closeClick"></UpdateData>
    <BorrowBackModal :visible="visible1" :type="type" :detail="detail" @close="closeBorrowClick"></BorrowBackModal>
  </div>
</template>

<script>
import ListHeader from '@/components/ListHeader.vue'
import UpdateData from '@/components/UpdateData.vue'
import BorrowBackModal from '@/components/BorrowBackModal.vue'
import RecordExportModal from '@/components/RecordExportModal.vue'
import { deleteEvent } from '@/mixins/tableOptionEvents'
import merge from "webpack-merge"
import { ref, getCurrentInstance, onMounted  } from 'vue'
import { useRouter,useRoute } from 'vue-router'
export default {
  mixins: [ deleteEvent ],
  components: {
    ListHeader,
    UpdateData,
    BorrowBackModal,
    RecordExportModal
  },
  setup() {
    const { appContext: { config : { globalProperties } } } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    let totalNum = ref(0);
    let pageSize = ref(15);
    let pageNum = ref(1);
    let tableData = ref([]);
    let checkedList = ref([]);
    let value = ref(route.query.search || '');
    let visible = ref(false);
    let visible1 = ref(false);
    let visible2 = ref(false);
    let detail = ref(null);
    let type = ref(null);

    function showPage(type = null) {
      if(type == 1 && checkedList.value.length == tableData.value.length) pageNum--;
      globalProperties.$API.StandardList({
        pageNum: pageNum.value,
        pageSize: pageSize.value,
        keyword: value.value
      }).then(res => {
        if(res.data.code == 200) {
          tableData.value = res.data.data.list;
          totalNum.value = res.data.data.total;
        }
      })
    }

    function pageChange(e) {
      pageNum.value = e;
      showPage();
    }

    function searchClick(data) {
      router.replace({
        query: merge(route.query, { search: data || '' }),
      });
      value.value = data;
      pageNum.value = 1;
      showPage()
    }

    function handleSelectionChange(data) {
      checkedList.value = data
    }

    function closeClick(type) {
      if(!type) {
        visible.value = false;
        detail.value = null;
      }else {
        visible.value = false
        pageNum.value = 1;
        value.value = '';
        router.replace({
          query: merge(route.query, { search: '' }),
        });
        detail.value = null;
        showPage();
      }
    }

    function closeBorrowClick(type) {
      if(type == true) showPage();
      detail.value = null;
      visible1.value = false
    }
    function closeExportClick() {
      visible2.value = false
    }

    onMounted(()=>{
      showPage()
    })

    return {
      totalNum,
      pageSize,
      pageNum,
      tableData,
      checkedList,
      value,
      visible,
      visible1,
      visible2,
      detail,
      type,
      showPage,
      pageChange,
      searchClick,
      handleSelectionChange,
      closeClick,
      closeBorrowClick,
      closeExportClick,
    }
  },
}
</script>
<style lang='scss'>
  #searchList {
    padding-top: 110px;

    .search_inner {
      width: 1200px;
      margin: 0 auto;

      .inner_top {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        & > p {
          font-size: 14px;
          color: #909399;
          line-height: 40px;
          width: 1100px;
        }

        .el-button {
          height: 40px;
          border-radius: 4px;
          font-size: 14px;
          margin-left: 10px;
        }
        
      }

      .el-table .el-table__cell {
        padding: 13px 0;
      }

      .el-table .cell {
        line-height: 20px;
        padding: 0 10px;

        .el-button {
          padding: 0;
          line-height: 20px;
          height: 20px;
        }

        .el-button+.el-button {
           margin-left: 5px;
        }
      }

      .el-pagination {
        margin: 30px auto;
        text-align: center;
        width: 100%;
        justify-content: center;
      }
    }
  }
</style>
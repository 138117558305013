<template>
  <div class="list_header">
    <div class="inner">
      <a href="#/home"><img src="../assets/image/logo.png" /></a>
      <div class="search">
        <el-input 
          v-model="value" 
          placeholder="请输入标准号 / 标准名称" 
          clearable 
          @keydown="keydownClick"
          @input=inputClick />
        <el-button type="primary" @click="searchClick">检索</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
export default {
  setup(props, context) {
    const route = useRoute();
    let value = ref(route.query.search || '');

    function keydownClick(e) {
      if(e.keyCode == 13) {
        searchClick()
      }
    }

    function searchClick() {
      if(!value.value || !value.value.trim()) {
        return ElMessage.error('请输入搜索内容')
      }else {
       context.emit('search', value.value)
      }
    }
    function inputClick(e) {
      if(!e) context.emit('search', null)
    }

    return {
      value,
      keydownClick,
      searchClick,
      inputClick
    }
  }
  // mounted() {
  //   this.value = this.$route.query.search
  // },
  // methods: {
  //   keydownClick(e) {
  //     if(e.keyCode == 13) {
  //       this.searchClick()
  //     }
  //   },
  //   searchClick() {
  //     if(!this.value || !this.value.trim()) {
  //       return this.$message.error('请输入搜索内容')
  //     }else {
  //       this.$emit('search', this.value)
  //     }
  //   },
  //   inputClick(e) {
  //     if(!e) this.$emit('search', null)
  //   }
  // }
}
</script>

<style lang='scss'>
  .list_header {
    width: 100%;
    height: 90px;
    background: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    border-bottom: 1px solid #EBEBEB;

    .inner {
      width: 1200px;
      padding: 20px 0;
      display: flex;
      align-items: center;
      margin: 0 auto;

      img {
        width: 189px;
        height: 50px;
        margin-right: 30px;
      }

      .search {
        display: flex;
        justify-content: center;
        align-items: center;

        .el-input {
          width: 300px;
          height: 40px;
          border-radius: 4px;
        }

        .el-button {
          margin-left: 6px;
          width: 80px;
          height: 40px;
          border-radius: 4px;
          font-size: 14px;
        }
      }

    }
  }
</style>
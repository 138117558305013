<template>
  <el-dialog
    v-model="visible"
    title="借阅记录导出"
    width="480px"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close="closeClick"
  >
    <el-form :model="form" label-position="top">
      <el-form-item label="时间范围（导出选定时间段的所有标准的借阅和归还记录）" prop="time">
        <el-radio-group v-model="form.time">
          <el-radio :label="0">最近7天</el-radio>
          <el-radio :label="1">最近30天</el-radio>
          <el-radio :label="2">最近180天</el-radio>
          <el-radio :label="3">今年</el-radio>
          <el-radio label="all">全部</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="$emit('close', false)">取消</el-button>
        <el-button type="primary" @click="confirmClick">执行</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { getCurrentInstance, ref  } from 'vue'

export default {
  props: ['visible'],
  
  setup(props, context) {
    let form = ref({ time: 0 });
    const { appContext: { config : { globalProperties } } } = getCurrentInstance();

    function confirmClick() {
      window.location = globalProperties.$API.ExportRecord({
        selectDate: form.value.time == 'all' ? null : form.value.time
      })
      closeClick();
    }

    function closeClick() {
      form.value = { time: 0 }
      context.emit('close', false)
    }

    return {
      form,
      confirmClick,
      closeClick
    }
  }
  // data() {
  //   return {
  //     form: {
  //       time: 0
  //     }
  //   }
  // },
  // methods: {
  //   confirmClick() {
  //     window.location = this.$API.ExportRecord({
  //       selectDate: this.form.time == 'all' ? null : this.form.time
  //     })
  //     this.closeClick();
  //   },
  //   closeClick() {
  //     this.form = {
  //       time: 0
  //     }
  //     this.$emit('close', false)
  //   }
  // }
}
</script>

<style lang='scss'>
  .el-dialog {
    margin-top: 0;
    top: 50%;
    transform: translate(0, -50%);

    .el-dialog__header {
      padding: 15px;
      margin-right: 0;

      .el-dialog__headerbtn {
        width: 16px;
        height: 16px;
        margin-top: 13px;
        margin-right: 15px;

      }
    }

    .el-dialog__body {
      padding: 0 15px;

      .el-form-item {
        margin-bottom: 20px;
      }

      .el-radio {
        margin-right: 20px;
      }
    }

    .el-dialog__footer {
      padding: 0 15px 15px;
    }
  }
</style>
<template>
  <el-dialog
    v-model="visible"
    :title="type == 1 ? '借出' : '归还'"
    width="420px"
    :close-on-click-modal="false"
    :append-to-body="true"
    :destroy-on-close="true"
    @close="closeClick"
  >
    <el-form :model="form" ref="formRef" label-width="68px" :rules="rules">
      <el-form-item label="总数量">
        <el-input v-model="form.total" disabled />
      </el-form-item>
      <el-form-item label="当前库存" prop="inventory">
        <el-input v-model="form.inventory" disabled />
      </el-form-item>
      <template v-if="type == 1">
        <el-form-item label="本次借出" prop="calculate">
          <el-input-number v-model="form.calculate" :max="parseInt(form.inventory)" :precision="0" :min="0" />
        </el-form-item>
        <el-form-item label="借阅人" prop="name">
          <el-input v-model="form.name" placeholder="请输入借阅人" />
        </el-form-item>
      </template>
      <template v-if="type == 2">
        <el-form-item label="本次归还" prop="calculate">
          <el-input-number v-model="form.calculate" :max="parseInt(form.total) - parseInt(form.inventory)" :precision="0" :min="0" />
        </el-form-item>
        <el-form-item label="归还人" prop="name">
          <el-input v-model="form.name" placeholder="请输入归还人" />
        </el-form-item>
      </template>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="form.remark" placeholder="请输入备注" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="$emit('close', false)">取消</el-button>
        <el-button type="primary" @click="confirmClick">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { computed, getCurrentInstance, ref, watch, nextTick  } from 'vue'
import { ElMessage } from 'element-plus'
export default {
  props: ['visible', 'detail', 'type'],
  setup(props, context) {
    const { appContext: { config : { globalProperties } } } = getCurrentInstance();
    let formRef = ref(null);
    let form = ref({
      calculate: 0,
      name: '',
      remark: '',
    })
    let one = ref(false);
    const rules = computed(() => {
      return {
        calculate: [
          { required: true, message: props.type == 1 ? '请输入本次借出数量' : '请输入本次归还数量', trigger: 'blur' },
        ],
        name: [
          { required: true, message: props.type == 1 ? '请输入借阅人' : '请输入归还人', trigger: 'blur' },
        ],
      }
    })

    watch(() => props.detail, (n, o) => {
      if(n) {
        nextTick(() => { formRef.value && formRef.value.resetFields() })
        form.value = { ...n };
        form.value.calculate = 0;
      }
    })

    const confirmClick = () => {
      if(one.value) return;
      else one.value = true;
      formRef.value.validate((valid) => {
        if(valid) {
          let api = props.type == 1 ? 'BorrowStandard' : 'ReturnStandard'
          globalProperties.$API[api]({
            standardId: props.detail && props.detail.id || null,
            name: form.value.name,
            calculate: form.value.calculate,
            status: props.type == 1 ? 1 : 0,
            remark: form.value.remark
          }).then(res => {
            if(res.data.code == 200) {
              ElMessage.success('操作成功');
              one.value = false;
              // Object.assign(this.$data, this.$options.data());
              context.emit('close', true)
            }else {
              one.value = false;
              ElMessage.error( res.data.msg || '操作失败')

            }
          })
        }else {
          one.value = false;
        }
      })
    }
    const closeClick = () => {
      form.value = {
        calculate: 0,
        name: '',
        remark: '',
      }
      formRef.value.resetFields()
      context.emit('close', false)
    }

    return {
      form,
      one,
      rules,
      formRef,
      confirmClick,
      closeClick
    }
  },
  // data() {
  //   return {
  //     form: {
  //       calculate: 0,
  //       name: '',
  //       remark: '',
  //     },
  //     one: false,
  //   }
  // },
  // computed: {
  //   rules() {
  //     return {
  //       calculate: [
  //         { required: true, message: this.type == 1 ? '请输入本次借出数量' : '请输入本次归还数量', trigger: 'blur' },
  //       ],
  //       name: [
  //         { required: true, message: this.type == 1 ? '请输入借阅人' : '请输入归还人', trigger: 'blur' },
  //       ],
  //     }

  //   }
  // },
  // watch: {
  //   detail: {
  //     handler(n) {
  //       if(n) {
  //         this.form = { ...n };
  //         this.form.calculate = 0;
  //         // this.$refs.form.resetFields()
  //       }
  //     }
  //   }
  // },
  // methods: {
  //   confirmClick() {
  //     if(this.one) return;
  //     else this.one = true;
  //     this.$refs.form.validate((valid) => {
  //       if(valid) {
  //         let api = this.type == 1 ? 'BorrowStandard' : 'ReturnStandard'
  //         this.$API[api]({
  //           standardId: this.detail && this.detail.id || null,
  //           name: this.form.name,
  //           calculate: this.form.calculate,
  //           status: this.type == 1 ? 1 : 0,
  //           remark: this.form.remark
  //         }).then(res => {
  //           if(res.data.code == 200) {
  //             this.$message.success('操作成功');
  //             this.one = false;
  //             Object.assign(this.$data, this.$options.data());
  //             this.$emit('close', true)
  //           }else {
  //             this.one = false;
  //             this.$message.error( res.data.msg || '操作失败')

  //           }
  //         })
  //       }else {
  //         this.one = false;
  //       }
  //     })
  //   },
  //   closeClick() {
  //     this.form = {
  //       standardNumber: '',
  //       standardName: '',
  //       position: '',
  //       num: 0,
  //       num1: 0,
  //     }
  //     this.$refs.form.resetFields()
  //     this.$emit('close', false)
  //   }
  // }
}
</script>

<style lang='scss'>
  .el-dialog {
    margin-top: 0;
    top: 50%;
    transform: translate(0, -50%);

    .el-dialog__header {
      padding: 15px;
      margin-right: 0;

      .el-dialog__headerbtn {
        width: 16px;
        height: 16px;
        margin-top: 13px;
        margin-right: 15px;

      }
    }

    .el-dialog__body {
      padding: 0 15px;

      .el-form-item {
        margin-bottom: 20px;
      }

      .el-select {
        width: 332px;
      }

      .el-form-item__label,
      .el-form-item__content,
      .el-select,
      .el-input {
        height: 36px;
        line-height: 36px;
      }
    }

    .el-dialog__footer {
      padding: 0 15px 15px;
    }

     .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
       margin-right: 0;
       content: '';
     }
  }
</style>